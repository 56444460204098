var products_loading = false;
var products_loaded = false;
var init = false;
var xhr = null;

$(document).ready(function(){
	$('[data-pimcore-component="products-filter"] input:not([type="search"]), [data-pimcore-component="products-filter"] select').change(update_filter);

	$('[data-pimcore-component="clear-filter"]').on('click', clear_filter);

	var timeout = null;
	$('[data-pimcore-component="products-filter"] input[type="search"]').keyup(function(){
		if (timeout != null) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(function(){
			$('form[data-pimcore-component="products-filter"] input[type="checkbox"]').prop('checked', false);
			$('form[data-pimcore-component="products-filter"] select').val('');
			$('form[data-pimcore-component="products-filter"] input[data-component="filter-theme"]').val('');
			update_filter();
		}, 500);
	});

	$('[data-pimcore-component="products"]').each(function(){
		//Load ajax products of category
		$(this).attr('data-page', 1),
		update_filter();
	});
});

function update_filter(){
		if (xhr != null) {
			xhr.abort();
		}
		$container = $('[data-pimcore-component="products"]').eq(0);

		var filter_params = $('[data-pimcore-component="products-filter"]').serializeJSON();
		var filters = JSON.parse(filter_params);

		for (var param in filters) {
			if (filters[param] == '') {
				delete filters[param];
			}else if(typeof filters[param] == 'object'){
				if(filters[param].filter){
					var filtered = filters[param].filter(function (el) {
					  return el != '';
					});
					filters[param] = filtered;
					if (filtered.length == 0) {
						delete filters[param];
					}
				}
			}
		}

		$('[data-pimcore-component="clear-filter"]').addClass('active');
		for (const property in filters) {
			$('[data-pimcore-component="clear-filter"][data-pimcore-name="' + property + '"]').removeClass('active');
		}

		var params_url = jQuery.param( filters );
		filter_params = JSON.stringify(filters);
		$container.attr('data-filter', filter_params);
		var page_url = location.protocol + '//' + location.host + location.pathname;

		if (params_url != '') {
			window.history.pushState("", "", page_url+'?'+params_url);
		}else{
			window.history.pushState("", "", page_url);
		}

		$container.find('.wrapper').html('');
		$container.attr('data-page', 1);
		products_loaded = false;
		load_category_products($container);
}

function init_scroll(){
	init = true;
	if ($('[data-pimcore-component="products"]').length > 0) {
		$container = $('[data-pimcore-component="products"]').eq(0);
		$(window).scroll(function() {
			if(($(window).scrollTop() + $(window).height()) > ($container.offset().top + $container.outerHeight()) && products_loading == false && products_loaded == false) {
		        //products_loading = true;
				var page = parseInt($container.attr('data-page')) + 1;
				$container.attr('data-page', page),
				load_category_products($container);
		    }
		});
	}
}

function clear_filter(){
	let name = $(this).attr('data-pimcore-name');
	$('input[name="'+name+'[]"]').prop('checked', null);

	$(this).addClass('active');

	update_filter();
}

function load_category_products(element){
	if (xhr != null) {
		xhr.abort();
	}
	if (products_loaded == false) {
		products_loading = true;
		$('.products-empty', element).hide();
		$('.products-loader', element).show();

		xhr = $.ajax({
			method: "POST",
			url: "/ajax/pimcore/"+site_id+"/"+lang,
			data: {category: $(element).attr('data-pimcore-category'), filter: $(element).attr('data-filter'), page: $(element).attr('data-page')},
			headers: {
	       		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	    	}
		}).done(function( result ) {
			if (result == '') {
				if ($('.wrapper', element).html() == '') {
					$('.products-empty', element).show();
				}
				products_loaded = true;
			}
			if (init == false) {
				init_scroll();
			}
			xhr = null;

			$('.wrapper', element).append(result);

			products_loading = false;
			$('.products-loader', element).hide();
		});
	}
}